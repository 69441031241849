var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li class=\"selected-goods__item\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":8,"column":48},"end":{"line":8,"column":54}}}) : helper)))
    + "\">\n        <img class=\"selected-goods__item-img\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mainImg") || (depth0 != null ? lookupProperty(depth0,"mainImg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mainImg","hash":{},"data":data,"loc":{"start":{"line":9,"column":51},"end":{"line":9,"column":62}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":9,"column":69},"end":{"line":9,"column":77}}}) : helper)))
    + "\" width=\"220\" height=\"180\">\n        <div class=\"selected-goods__item-block__info\">\n          <h4 class=\"selected-goods__item-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":11,"column":49},"end":{"line":11,"column":57}}}) : helper)))
    + "</h4>\n          <span class=\"selected-goods__item-price\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":12,"column":51},"end":{"line":12,"column":60}}}) : helper)))
    + " грн</span>\n          <p class=\"selected-goods__item-desc\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":13,"column":47},"end":{"line":13,"column":62}}}) : helper)))
    + "</p>\n        </div>\n        <span class=\"selected-goods__item-icon\">\n          <span class=\"selected-goods__icon-text\">Удалить <span class=\"selected-goods__icon-text--hidden\">с\n              избранных</span></span>\n        </span>\n      </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"selected-goods__inner\">\n  <span class=\"modal__icon--close close__favorites\"></span>\n  <div class=\"selected-goods\">\n    <h3 class=\"selected-goods__title icon--heart-on\">Избранное</h3>\n\n    <ul class=\"selected-goods__list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n  </div>\n</div>\n";
},"useData":true});