var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"account-btn\">\n  <div class=\"account-avatar\">\n    <img class=\"avatar avatar--hover\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"avatar") || (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":53}}}) : helper)))
    + "\" alt=\"Пользователь: "
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":3,"column":74},"end":{"line":3,"column":87}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"secondName") || (depth0 != null ? lookupProperty(depth0,"secondName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"secondName","hash":{},"data":data,"loc":{"start":{"line":3,"column":88},"end":{"line":3,"column":102}}}) : helper)))
    + "\" width=\"45\"\n      height=\"45\">\n  </div>\n  <span class=\"account-title\">Привет, "
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":6,"column":38},"end":{"line":6,"column":51}}}) : helper)))
    + " !</span>\n</button>";
},"useData":true});