var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"account-wrapper\">\n  <span class=\"modal__icon--close close__my-account\"></span>\n\n  <div class=\"user-avatar fileForm\">\n    <input class=\"user-avatar__file-input\" type=\"file\" name=\"file\" id=\"file-reader\">\n\n    <div class=\"js-avatar\">\n      <img class=\"avatar avatar--hover avatare-large\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"avatar") || (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar","hash":{},"data":data,"loc":{"start":{"line":8,"column":59},"end":{"line":8,"column":69}}}) : helper)))
    + "\" alt=\"Пользователь: "
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":8,"column":90},"end":{"line":8,"column":103}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"secondName") || (depth0 != null ? lookupProperty(depth0,"secondName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"secondName","hash":{},"data":data,"loc":{"start":{"line":8,"column":104},"end":{"line":8,"column":118}}}) : helper)))
    + "\"\n        width=\"85\" height=\"85\">\n    </div>\n\n    <label class=\"user-avatar__input-label\" for=\"file-reader\">\n      <span class=\"user-avatar__add-btn avatar__icon--camera__add\">\n        <span class=\"visually-hidden\">Load new avatar</span>\n      </span>\n    </label>\n\n    <label class=\"user-avatar__input-label\">\n      <span class=\"user-avatar__clear-btn avatar__icon--camera__clear\">\n        <span class=\"visually-hidden\">Clear your avatar</span>\n      </span>\n    </label>\n  </div>\n\n  <ul class=\"user-info\">\n    <li class=\"user-info__fullname\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":26,"column":36},"end":{"line":26,"column":49}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"secondName") || (depth0 != null ? lookupProperty(depth0,"secondName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"secondName","hash":{},"data":data,"loc":{"start":{"line":26,"column":50},"end":{"line":26,"column":64}}}) : helper)))
    + "</li>\n    <li class=\"user-info__mail\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":27,"column":32},"end":{"line":27,"column":41}}}) : helper)))
    + "</li>\n    <li class=\"user-info__phone\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":28,"column":33},"end":{"line":28,"column":42}}}) : helper)))
    + "</li>\n  </ul>\n\n  <ul class=\"account-list hr--line\">\n    <li class=\"account-list__favorites icon--heart\">\n      <span class=\"account-list__favorites-link\">Избранное</span>\n      <span class=\"js-counter__heart counter\">0</span>\n    </li>\n\n    <li class=\"account-list__advertisement icon--add\">\n      <span class=\"account-list__advertisement-link\">Мои объявления</span>\n      <span class=\"js-counter__add counter\">0</span>\n    </li>\n  </ul>\n\n  <button class=\"auth-form-sign-out account-logout icon--logout\" data-btn=\"signout\">Выйти</button>\n</div>";
},"useData":true});