export default {
  body: document.querySelector('body'),
  authBlock: document.querySelector('.header-auth'),
  authBlockMobile: document.querySelector('.header-auth-mobile'),
  clearBlock: document.querySelector('.clear-filters-wrapper'),
  clearBlockMobile: document.querySelector('.mobile-clear-filters-wrapper'),
  mobileMenuClosed: document.querySelector('.mobile-menu-closed'),
  mobileMenuOpened: document.querySelector('.mobile-menu-opened'),
  mobileFiltersBtn: document.querySelector('.mobile-filters-wrapper'),
  categories: document.querySelector('.categories-filter'),
  categoriesMobile: document.querySelector('.categories-filter-mobile'),
  categoriesTablet: document.querySelector('.categories-filter-tablet'),
  tabletFiltersBtn: document.querySelector('.filters-wrapper'),
  mobileBurger: document.querySelector('.mobile-burger'),
  mobileSearch: document.querySelector('.mobile-search'),
  mobileInput: document.querySelector('.mobile-input-closed'),
  mobileInputOpened: document.querySelector('.mobile-input'),
  inputSearch: document.querySelector('.mobile-input-btn'),
  inputCross: document.querySelector('.mobile-input-cross'),
  cross: document.querySelector('.close-menu'),
  header: document.querySelector('.header'),
  wholeCategory: document.querySelector('.all-category'),
  tabletInput: document.querySelector('.header__input--tablet'),
  PCInput: document.querySelector('.header__input--pc'),
  inputButtonPcTablet: document.querySelectorAll('.header__input--btn'),
  sectionAds: document.querySelector('.ads'),
  sectionCategories: document.querySelector('.categories'),
  loadMore: document.querySelector('.load-more'),
  closeCategory: document.querySelector('.close-category'),
  logo: document.querySelector('.header__logo'),
};
