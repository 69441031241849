export default {
  allCategories: [],
  property: [],
  transport: [],
  work: [],
  electronics: [],
  businessAndServices: [],
  recreationAndSport: [],
  free: [],
  trade: [],
  advertisement: [],
};
