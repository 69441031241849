var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"category-list "
    + alias4(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":38}}}) : helper)))
    + "-wrapper \">\n    <div class=\"category-info\">\n        <span class=\"category-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"nameCategory") || (depth0 != null ? lookupProperty(depth0,"nameCategory") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nameCategory","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":52}}}) : helper)))
    + "</span>\n        <a class=\"view-all \" data-category='"
    + alias4(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":4,"column":44},"end":{"line":4,"column":56}}}) : helper)))
    + "'>Смотреть все</a>\n    </div>\n    <p class=\"category-description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"descriptionCategory") || (depth0 != null ? lookupProperty(depth0,"descriptionCategory") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"descriptionCategory","hash":{},"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":6,"column":59}}}) : helper)))
    + ".</p>\n    <ul class=\"things-list "
    + alias4(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":7,"column":27},"end":{"line":7,"column":39}}}) : helper)))
    + "-list show-all\">\n    </ul>\n    <button class=\"slide-prev\"></button>\n    <button class=\"slide-next\"></button>\n</div>";
},"useData":true});