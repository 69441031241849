var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <li class=\"slider_image-min\"><img class=\"sm-photo--style\" src=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\" alt=\"\">\n                    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <img class=\"item_modal--slider_s-img_big \" src=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"wrapper-item-modal\">\n    <button class=\"icon-cross\"></button>\n    <ul class=\"item_modal--tablet_wrapper\">\n        <li class=\"item_modal--tablet_slider\">\n            <div class=\"slider_image-max\">\n                <img class=\"bg-photo--style\" id=\"bg-photo--style\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mainImg") || (depth0 != null ? lookupProperty(depth0,"mainImg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mainImg","hash":{},"data":data,"loc":{"start":{"line":6,"column":71},"end":{"line":6,"column":82}}}) : helper)))
    + "\" alt=\"\">\n            </div>\n            <div class=\"slider_image-mini\">\n\n                <ul class=\"images-mini\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":14,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n\n            </div>\n\n        </li>\n        <li class=\"item_modal--tablet-second_wrapper\">\n\n            <h3 class=\"item_modal--header\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":51}}}) : helper)))
    + "</h3>\n            <p class=\"item_modal--item-codename\">Код товара | <span class=\"item_modal--item-code\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":23,"column":98},"end":{"line":23,"column":104}}}) : helper)))
    + "</span></p>\n            <div class=\"siema item_modal--imageholder\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":27,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n            <p class=\"item_modal--item-price\"><span class=\"item_modal--item-pricevalue\"> "
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":29,"column":89},"end":{"line":29,"column":98}}}) : helper)))
    + " </span>грн\n            </p>\n            <button class=\"item_modal--tablet--button-buy\">Информация о продавце</button>\n            <div class=\"show-hide-phone\">\n                <ul class=\"item_modal-seller\">\n                    <li><img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"avatar") || (depth0 != null ? lookupProperty(depth0,"avatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar","hash":{},"data":data,"loc":{"start":{"line":34,"column":34},"end":{"line":34,"column":44}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":34,"column":51},"end":{"line":34,"column":64}}}) : helper)))
    + "\" class=\"avatarAuth\"></li>\n                    <li>\n                        <p class=\"item_modal-seller--header\">Продавец: <span\n                                class=\"item_modal-seller--name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":37,"column":64},"end":{"line":37,"column":77}}}) : helper)))
    + "</span>\n                        </p>\n                        <p class=\"item_modal-seller--tel\"><span class=\"phone-item-icon\"></span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":39,"column":95},"end":{"line":39,"column":104}}}) : helper)))
    + "</p>\n                    </li>\n                </ul>\n            </div>\n            <ul class=\"item_modal--icons\">\n                <li class=\"item_modal--button-fav\"><button class=\"icon-fav\"></button><span class=\"fav-text\">В\n                        избранное</span>\n                </li>\n            </ul>\n            <p class=\"item_modal--desc-header\">Описание</p>\n            <p class=\"item_modal--desctab\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":49,"column":43},"end":{"line":49,"column":58}}}) : helper)))
    + "</p>\n\n        </li>\n    </ul>\n</div>";
},"useData":true});